'use client'
import { containerBreakpoints } from '@/components/breakpoints'
import CustonButton from '@/components/button'
import { Box, SimpleGrid, Image, Text, List, ListItem, HStack, IconButton, Container, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FaInstagram, FaLinkedin, FaYoutube, FaFacebookF } from 'react-icons/fa'

export default function FooterComponent(props: any) {
  const { data, config, menu } = props
  if (!data || !config || !menu) return null
  const socialButtons: { [key: string]: any } = {
    'LinkedIn': <FaLinkedin />,
    'Facebook': <FaFacebookF />,
    'Youtube': <FaYoutube />,
    'Instagram': <FaInstagram />,
  }

  return (
    <>
      <Box as="footer" className={data?.ClasseCss} p={'60px 0 100px'} bgPosition={'top center'} pos={'relative'} rounded={0} bgSize={'cover'} bgRepeat={'no-repeat'} bgImage={'https://dr7dcgej7t392.cloudfront.net/uploads/bg_rodape_48d6421216.webp'}>
        <Container maxW={[540, 540, 1300]} paddingX={'15px'}>
          <Box>
            <SimpleGrid pb={'65px'} columns={2} spacing={10}>
              <NextLink href='#'>
                <Image htmlWidth="160" htmlHeight="65" src={data?.Logo?.data?.attributes?.url} alt='Brasal' w={'160px'} />
              </NextLink>
              <HStack justifySelf={'flex-end'}>
                {config?.RedesSociais.map((item: any, index: number) => {
                  return (
                    <NextLink prefetch href={item?.Link || '#'} key={`footer-social-${item?.id}`}>
                      <IconButton 
                        minW={''}
                        fontSize={{ base: 18, md: 20 }}
                        w={{ base: '34px', md: '55px' }}
                        h={{ base: '34px', md: '55px' }}
                        aria-label={item?.Nome}
                        color={'white'}
                        icon={socialButtons[item?.Nome]}
                        borderRadius={'50%'}
                        bg={'black'}
                        transition={'all 500ms ease'}
                        _hover={{ bg: '#cc0007' }} />
                    </NextLink>
                  )
                })}
              </HStack>
            </SimpleGrid>
            <HStack width={'100%'} flexWrap={'wrap'} gap={0} alignItems={'start'}>
              <Box width={{ lg: '50%', md: '100%', sm: '100%', base: '100%' }} className={data?.InfoEsquerda?.ClasseCss}>
                <Text
                  className='footer-col-1-title'
                  fontWeight={'800'}
                  fontSize={{ base: 19, sm: 19, md: 24 }}
                  color={'#cc0007'}
                  lineHeight={{ base: '27px', sm: '27px', md: '40px' }}
                  letterSpacing={'-.96px'}
                  mb={'9px'}>
                  {data?.InfoEsquerda?.Titulo}
                </Text>
                <Box
                  className='footer-col-1-text'
                  fontFamily={"'Plus Jakarta Sans', sans-serif"}
                  fontWeight={'400'}
                  fontSize={{ base: '19px', sm: '19px', md: '24px' }}
                  color={'#5d5d5d'}
                  lineHeight={'33px'}
                  pb={'29px'}
                  letterSpacing={'-.96px'}>
                  <span dangerouslySetInnerHTML={{ __html: data?.InfoEsquerda?.Descricao }}></span>
                </Box>
                <Box mb={{ base: '20px', sm: '20px', md: 'auto' }}>
                  <CustonButton
                    
                    href={data?.InfoEsquerda?.PaginaExt || data?.InfoEsquerda?.PaginaInt?.data?.attributes?.Slug || '#'}
                    padding='10px 60px'
                    w={{ base: 'full', sm: 'full', md: 'auto' }}
                    textAlign={{ base: 'center', md: 'auto' }}>{data?.InfoEsquerda?.TextoLink}</CustonButton>
                </Box>
              </Box>
              <Box width={{ lg: '16.6666666667%', md: '50%', sm: '100%', base: '100%' }} mt={{ base: '0', sm: '30px', md: '0' }}>
                <List>
                  {menu?.items?.data.map((item: any, index: number) => {
                    if (item?.attributes?.order == 0) {
                      return (
                        <ListItem key={`footer-menu-item-${item?.id}`} className='footer-col-2-title'>
                          <Text fontWeight={'800'} fontSize={'16px'} color={'#cc0007'} lineHeight={'36px'} mb={'9px'}>
                            {item?.attributes?.title}
                          </Text>
                        </ListItem>
                      )
                    }                
                  })}

                  {menu?.items?.data.map((item: any, index: number) => {
                    return (item?.attributes?.children?.data.map((itemSub: any, index: number) => {
                      return (
                        <ListItem fontSize={16} fontWeight={'400'} lineHeight={'36px'} key={`footer-menu-item-${itemSub.id}`}>
                          <NextLink prefetch href={itemSub?.attributes?.url || '#'} target={itemSub?.attributes?.target} passHref legacyBehavior >
                            <Link href='#' textDecoration={'none'} _hover={{ color: '#cc0007' }}>
                            {itemSub?.attributes?.title}
                            </Link>
                          </NextLink>
                        </ListItem>
                      )
                    }))                   
                  })}
                </List>
              </Box>
              <Box width={{ lg: '33.3333333333%', md: '50%', sm: '100%', base: '100%' }} mt={{ base: '0', sm: '30px', md: '0' }} className={data?.InfoDireita?.ClasseCss}>
                <Text fontWeight={'800'} fontSize={'16px'} color={'#cc0007'} lineHeight={{ sm: '26px', md: '36px' }} letterSpacing={'-.96px'} mb={'9px'}>
                  {data?.InfoDireita?.Titulo}
                </Text>
                <Text className="txt-rodape-direita" fontSize={16} fontWeight={'400'} lineHeight={{ sm: '26px', md: '36px' }} color={'#5d5d5d'} display={'inline-block'}>
                  <span dangerouslySetInnerHTML={{ __html: data?.InfoDireita?.Descricao }}></span>
                </Text>
                {data?.InfoDireita?.TextoLink && <Box display={'flex'} justifyContent={'end'} pt={'12px'}>
                    <CustonButton href={data?.InfoDireita?.PaginaExt || data?.InfoDireita?.PaginaInt?.data?.attributes?.Slug} target={data?.InfoDireita?.PaginaExt ? "_blank" : ""} className="conteudo-rodape-botao">{data?.InfoDireita?.TextoLink}</CustonButton>
                </Box>}
              </Box>
            </HStack>
            <Image className="rodape-arco" src='https://dr7dcgej7t392.cloudfront.net/uploads/bg_rodape_arco_a72be68afc.webp' alt='Brasal' pos={'absolute'} bottom={0} left={0} />
          </Box>
        </Container>
      </Box>
      <Box bg={'#282525'}>
        <Container maxW={1300}>
          <Text
            fontSize={{ base: 13, md: 16 }}
            textAlign={{ base: 'center', md: 'start' }}
            p={'10px 0'}
            color={'white'}
            fontWeight={500}
            lineHeight={1.875}>
            {config?.Copyright}
          </Text>
        </Container>
      </Box>
    </>
  )
  
}
